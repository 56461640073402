import * as faceapi from '@vladmandic/face-api'

export default class ExpressionDetector {
    constructor() {
        faceapi.nets.faceExpressionNet.loadFromUri('./models').then();
        faceapi.nets.ssdMobilenetv1.loadFromUri('./models').then();
        this.ssdMobilenetv1 = new faceapi.SsdMobilenetv1Options();
    }
    
    async detectExpressions(input) {
        try {
            const detectionsWithExpressions = await faceapi.detectAllFaces(input, this.ssdMobilenetv1).withFaceExpressions();
            return this.sortDominantExpressions(detectionsWithExpressions[0].expressions);
        } catch(err) {
            return null;
        }
    }

    sortDominantExpressions(expressions) {
        let keysSorted = Object.keys(expressions).sort(function(a, b) {return -(expressions[a] - expressions[b])});
        let output = [];
        for (let i = 0; i < Object.keys(expressions).length; i++){
            output.push(
                {
                expression: keysSorted[i],
                percentage: expressions[keysSorted[i]]
                }
            )
        }

        return output;
    }
}
