export default class Webcam {
    width = 320;
    height = 0;

    streaming = false;

    video = null;

    constructor() {
        window.addEventListener('load', this.startup, false);
    }


    startup() {
        this.video = document.getElementById('video');

        navigator.mediaDevices.getUserMedia({video: true, audio: false}).then((stream) => {
            this.video.srcObject = stream;
            this.video.play();
        }).catch((err) => {
            console.error(err);
        });

        this.video.addEventListener('canplay', (ev) => {
            if(!this.streaming) {
                this.height = this.video.videoHeight / (this.video.videoWidth / this.width);
            }

            if (isNaN(this.height)) {
                this.height = this.width / (4 / 3);
            }

            this.video.setAttribute('width', this.width);
            this.video.setAttribute('height', this.height);
            this.streaming = true;
        }, false);
    };

    getVideo() {
        return document.getElementById('video');
    }
}