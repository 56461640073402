class TextToSpeech{
    /**
     * Initialize the TextToSpeech
     * @param {String} voice_option_selector    The selector for the voice mananagement
     * @param {String} lang                     The default language
     */
    constructor(voice_option_selector, lang="fr"){
        this.lang = lang;
        this.avaible = true;
        let SpeechSynthesisUtterance = window.webkitSpeechSynthesisUtterance ||
        window.mozSpeechSynthesisUtterance ||
        window.msSpeechSynthesisUtterance ||
        window.oSpeechSynthesisUtterance ||
        window.SpeechSynthesisUtterance;

        if (SpeechSynthesisUtterance !== undefined){
            this.synth = new SpeechSynthesisUtterance();
            this.synth.lang = lang;
            let voices = window.speechSynthesis.getVoices();
            this.synth.voice = voices[0];
            for (let voice in voices){
                this.__appendOptionRequest(voice_option_selector, voice, voices[voice].name);
            }

            this.__changeListener(voice_option_selector, (event) => {
                let choice = event.target.value;
                this.synth.voice = voices[choice];
            });
        }
        else{
            this.available >>= 1;
        }
    }

    /**
     * Read text contains at text_to_read_selector when button_selector is clicked
     * @param {String} button_selector          The button 
     * @param {String} text_to_read_selector    The container
     */
    speak(button_selector, text_to_read_selector){
        if (this.avaible){
            this.__clickListener(button_selector, (event) => {
                event.preventDefault();
                this.synth.text = document.querySelector(text_to_read_selector).textContent;
                if (window.speechSynthesis.speaking){
                    window.speechSynthesis.cancel();
                }
                window.speechSynthesis.speak(this.synth);
            });
        }
        else{
            this.__propRequest(button_selector, 'disabled', true);
        }
    }

    __valueRequest(elem){
        let res = [];
        console.log(document.querySelector(elem));
        elem = document.querySelector(elem).children;
        for (let e of elem){
            if (e.outerHTML === "<br>") {
                e = e.nextSibling
            }
            if(e.value === undefined){
                res[res.length] = e.textContent;
            } 
            else {
                res[res.length] = e.value;
            }
        }
        return res;
    }

    __appendOptionRequest(elem, value, name){
        let elemChild = document.createElement("OPTION");
        elemChild.setAttribute("value", value);
        elemChild.appendChild(document.createTextNode(name));
        document.querySelector(elem).appendChild(elemChild);
    }

    __propRequest(elem, attribut, value){
        document.querySelector(elem).setAttribute(attribut, value);
    }

    __changeListener(elem, changeFunction){
        document.querySelector(elem).addEventListener("change", changeFunction);
    }

    __clickListener(elem, clickFunction){
        document.querySelector(elem).addEventListener("click", clickFunction);
    }
}


let textToSpeech = new TextToSpeech("#voice-selector");
export {textToSpeech as default };