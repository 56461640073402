import Webcam from './webcam';
import ExpressionDetector from './faceapi'
import {init, animate, updateText, displayExpression, playMusic, manageVolume} from './model';
import "../src/index.css";
import textToSpeech from './TextToSpeech';

function main() {
  init();
  animate();
  let webcam = new Webcam();
  let expressionDetection = new ExpressionDetector();
  textToSpeech.speak(".talk", "#explanation-text");

  function detectExpression() {
    let video = webcam.getVideo();
    if (video !== null) {
      expressionDetection.detectExpressions(video).then((result) => {
          try {
            if (result !== null){
              displayExpression(result[0].expression);
              updateText("Text",result[0].expression, parseFloat(result[0].percentage * 100).toFixed(2) + "%") ;
              // SubText order is inverted to match with onscreen display
              updateText("SubText0",result[1].expression, parseFloat(result[1].percentage * 100).toFixed(2) + "%") ;
              updateText("SubText1",result[2].expression, parseFloat(result[2].percentage * 100).toFixed(2) + "%") ;
              updateText("SubText2",result[3].expression, parseFloat(result[3].percentage * 100).toFixed(2) + "%") ;
              updateText("SubText3",result[4].expression, parseFloat(result[1].percentage * 100).toFixed(2) + "%") ;
              updateText("SubText4",result[5].expression, parseFloat(result[2].percentage * 100).toFixed(2) + "%") ;
              updateText("SubText5",result[6].expression, parseFloat(result[3].percentage * 100).toFixed(2) + "%") ;
              if(document.getElementById("explanation").style.top === "-100%"){
                playMusic(result[0].expression);
              }
            }
          } catch(err) {
            console.error(err);
          }
        });
    }
    setTimeout(detectExpression, 2000);
  }
  detectExpression();
}

main();
let button = document.getElementById("button");
button.onclick = function() {
  let explanation = document.getElementById("explanation");
  let volumeItem = document.getElementsByClassName("volume")[0];
  volumeItem.classList.remove("hide");
  panUp();
  
  function panUp() {
    if(explanation.style.top !== "-100%") {
      let currentPercentage = explanation.style.top.split("%")[0];
      if (currentPercentage === "") {
        currentPercentage = "0";
      }
      currentPercentage = parseInt(currentPercentage);
      const wantedPercentage = currentPercentage - 5;
      explanation.style.top = wantedPercentage + "%";
      setTimeout(panUp, 20);
    }
  }
}

let sound = document.getElementsByClassName("volume");
for (let elem of sound){
  elem.onclick = manageVolume;
}