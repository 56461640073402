class Translator {

    constructor() {
        this.translateExplanationText();
        this.translateButton();
        const selector = document.getElementById("lang-selector");

        selector.addEventListener("click", () => {
            this.translateExplanationText();
            this.translateButton();
        }) 
    }

    translateExpression(expression) {
        const language = this.getCurrentLanguage();
        if (language === "EN") {
            return expression;
        }
        switch (expression) {
            case "angry":
                return "en colère";
            case "disgusted":
                return "dégoûté";
            case "fearful":
                return "effrayé";
            case "happy":
                return "heureux";
            case "neutral":
                return "neutre";
            case "sad":
                return "triste";
            case "surprised":
                return "surpris";
            default:
                return "";
        }
    }

    translateExplanationText() {
        const language = this.getCurrentLanguage();
        const explanationText = document.getElementById("explanation-text");
        explanationText.textContent = "";
        if (language === "FR") {
            explanationText.insertAdjacentHTML('beforeEnd', `AI SEE U est une application, qui gr&acirc;ce &agrave; l'intelligence artificielle, va analyser ton visage et d&eacute;terminer ton expression faciale, si tu es heureux, en col&egrave;re, triste ou tout simplement neutre! 
            Un mod&egrave;le 3D va alors pouvoir afficher cette expression avec diverses couleurs et sera accompagn&eacute; par de la musique athmosph&eacute;rique qui cr&eacute;era une ambiance encore plus immersive. <br> <br>
            Autorise l'application &agrave; acc&eacute;der &agrave; ta webcam et commence &agrave; jouer avec AI SEE U d&egrave;s maintenant!`)
        } else {
            explanationText.insertAdjacentHTML('beforeEnd', `AI SEE U is an application that uses artificial intelligence to analyse your face and determine your facial expression, if you're happy, angry, sad ou simply neutra!
            A 3D model is then going to display your expression with multiple colors et will be accompanied by athmospheric music that'll create an ambient mood. <br> <br>
            Authorise the app to access your webcam and start playing with AI SEE U right now!`)
        }
    }

    translateButton() {
        const language = this.getCurrentLanguage();
        const button = document.getElementById("button");
        if (language === "FR") {
            button.textContent = `Ok, compris!`;
        } else {
            button.textContent = `Ok, got it!`;
        }
    }
    
    getCurrentLanguage() {
        const selector = document.getElementById("lang-selector");
        return selector.options[selector.selectedIndex].value;
    }
}

let translator = new Translator();
export {translator as default };