import * as THREE from "three";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {TweenLite, Cubic} from "gsap";
import TextSprite from "@seregpie/three.text-sprite";
import translator from "./translator";

let camera, scene, renderer;
let model;
let sound;
let skybox;

let masterVolume = 1;

let Expression = {
  "angry": "angry",
  "disgusted": "disgusted",
  "fearful": "fearful",
  "happy": "happy",
  "neutral": "neutral",
  "sad": "sad",
  "surprised": "surprised"
}

export function init() {
  const container = document.createElement('div');
  document.body.appendChild(container);

  scene = new THREE.Scene();
  scene.position.set(0, 0, 0);

  camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 2000);
  camera.position.set(0, 0, 0);

  renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.shadowMap.enabled = true;
  container.appendChild(renderer.domElement);

  const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
  hemiLight.position.set(0, 200, 0);
  scene.add(hemiLight);

  //BACKGROUND
  const skyboxSides = [
    "./skybox/right.png",
    "./skybox/left.png",
    "./skybox/top.png",
    "./skybox/bottom.png",
    "./skybox/front.png",
    "./skybox/back.png"
  ]
  const materialArray = [];
  for (let i = 0; i < 6; i++) {
    materialArray.push(new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(skyboxSides[i]),
      side:  THREE.BackSide
    }));
  }

  let skyboxGeometry = new THREE.BoxGeometry(8000, 8000, 8000);
  skybox = new THREE.Mesh(skyboxGeometry, materialArray);
  scene.add(skybox);

  // MODEL
  const fbxLoader = new FBXLoader();
  fbxLoader.load("./assets/face_AISEEU.fbx", (object) => {
    model = object
    const box = new THREE.Box3().setFromObject(object);
    
    const boxSize = box.getSize(new THREE.Vector3()).length();
    const boxCenter = box.getCenter(new THREE.Vector3());
    
    frameArea(boxSize * 1, boxSize, boxCenter, camera);
    scene.add(copyFBXModel());
  })

  // TEXT
  let sprite = new TextSprite({
    text: "",
    alignment: "left",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: 50,
    color: "#ffffff",
  });
  sprite.name = "Text";

  sprite.position.x = 400;
  sprite.position.y = 50;

  scene.add(sprite);

  let subSprite;
  
  for (let i = 0; i < 6; i++){
    subSprite= new TextSprite({
      text: "",
      alignment: "left",
      fontFamily: "Arial, Helvetica, sans-serif",
      fontSize: 30 - (4*i),
      color: "#ffffff",
    });
    subSprite.name = `SubText${i}`;
  
    subSprite.position.x = 400;
    subSprite.position.y = 0 - ((30-i) * i);
  
    scene.add(subSprite);
  }

  // SOUND
  sound = {
    "angry": new Audio(),
    "disgusted": new Audio(),
    "fearful": new Audio(),
    "happy": new Audio(),
    "neutral": new Audio(),
    "sad": new Audio(),
    "surprised": new Audio()
  }

  for (let item in sound){
    sound[item].setAttribute("preload", "auto");
    sound[item].setAttribute("controls", "none");
    sound[item].style.display = "none";
    sound[item].src = `./music/${item}.mp3`;
    sound[item].volume = 0;
    sound[item].autoplay = true;
    sound[item].loop = true;
    document.body.appendChild(sound[item]);
  };

  // sound.setAttribute("preload", "auto");
  // sound.setAttribute("controls", "none");
  // sound.style.display = "none";
  // sound.volume = 1;
  // sound.autoplay = true;
  // sound.loop = true;
  

  window.addEventListener('resize', onWindowResize);
}

// FROM: https://threejsfundamentals.org/threejs/lessons/threejs-load-gltf.html
function frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
  const halfSizeToFitOnScreen = sizeToFitOnScreen * 1;
  const halfFovY = THREE.MathUtils.degToRad(camera.fov * .5);
  const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);

  const direction = (new THREE.Vector3())
      .subVectors(camera.position, boxCenter)
      .multiply(new THREE.Vector3(1, 0, 1))
      .normalize();

  camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

  camera.near = boxSize / 100;
  camera.far = boxSize * 100;

  camera.updateProjectionMatrix();

  camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
}


function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}

export function animate() {
  requestAnimationFrame( animate );
  renderer.render( scene, camera );
  skybox.rotation.x += 0.001;
  skybox.rotation.y += 0.001;
  skybox.rotation.z += 0.001;
}

export function updateText(objectName, expression, percentage) {
  let text = scene.getObjectByName(objectName);
  text.text = `${translator.translateExpression(expression)} - ${percentage}`;
}

function copyFBXModel() {
  let geometry = model.children[0].geometry;

  const material = new THREE.MeshPhongMaterial({color: 0x222222, flatShading: true, emissive: 0x333333, shininess: 150});
  const mesh = new THREE.Mesh(geometry, material);

  mesh.rotation.y = Math.PI;

  mesh.name = "Face";

  return mesh;
}


function getRandomNumberInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export function displayExpression(expression) {
  let mesh = scene.getObjectByName("Face");
  const hueRange = getHueRange(expression);
  const saturation = getSaturation(expression);
  const lightRange = getLightRange(expression);
  
  const color = new THREE.Color();
  color.setHSL(
    getRandomNumberInRange(hueRange[0], hueRange[1]) / 360, 
    saturation,
    getRandomNumberInRange(lightRange[0], lightRange[1])
  );

  TweenLite.to(mesh.material.color, 1.5, {
        r: color.r,
        g: color.g,
        b: color.b,
        ease: Cubic.easeInOut});
  
}

function getHueRange(expression) {
  switch(expression) {
    case Expression.angry:
      return [0, 20];
    case Expression.disgusted:
      return [90, 135];
    case Expression.fearful:
      return [260, 270];
    case Expression.happy:
      return [20, 50];
    case Expression.neutral:
      return [0, 0];
    case Expression.sad:
      return [180, 240];
    case Expression.surprised:
      return [280, 300];
    default:
      break;
  }
}

function getSaturation(expression) {
  return expression === Expression.neutral ? 0 : 1;
}

function getLightRange(expression) {
  return expression === Expression.neutral ? [0, 1] : [0.2, 0.5];
}

export function playMusic(expression) {
  
  for (let item in sound){
    if (item === expression) {
      TweenLite.to(sound[expression], 1, {volume : 1*masterVolume});
      // sound[expression].volume = 1;
    } else {
      TweenLite.to(sound[item], 1, {volume : 0});
      // sound[item].volume = 0;
    }
  }
  
}

export function manageVolume(){
  masterVolume ^= 1;
  let icon = document.getElementsByClassName("volume");
  for (let elem of icon){
    elem.classList.toggle("hide");
  }
}